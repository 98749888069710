import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { QueryClientProvider } from "react-query";
import { useDispatch } from "react-redux";
import { createBrowserRouter, RouterProvider, Outlet, useLocation, useNavigate, useBlocker } from "react-router-dom";

import "./App.css";
import { AppQueryClient } from "./api/queryClient";
import "./assets/css/Colors.css";
import "./assets/css/UtilityClasses.css";
import NoInternetMarker from "./components/common/NoInternetMarker";
import FullScreenLoader from "./components/common/loaders/FullScreenLoader";
import PreLoader from "./components/common/loaders/PreLoader";
import ControlBar from "./components/common/mainApp/ControlBar";
import FiltersHeader from "./components/common/mainApp/FiltersHeader";
import Footer from "./components/common/mainApp/Footer";
import Header from "./components/common/mainApp/Header";
import NavigationBar from "./components/common/mainApp/NavigationBar";
import * as Library from "./utils/Library";
import { setData } from "./utils/redux/userDataSlice";
import { APP_HOMEPAGE_ROUTE } from "./config/constant";
import TermsAndConditions from "./components/common/termsandConditions/TermsAndConditions";
import { navigationBar } from "./config/navigationBarConfig";
import AppContext from "./AppContext";
import { AllRoutes } from "./routes/AllRoutes";
import ProductsSidePanel from "./components/common/mainApp/ProductsSidePanel";
import FullScreenOverlay from "./components/common/overlay/FullScreenOverlay";
import DialogBoxComponent from "./components/common/dialogs/DialogBoxComponent";
import MobileNotAvailable from "./components/pages/MobileNotAvailable";
import { ToastContainer } from "react-toastify";
import PopupManager from "./components/common/PopupManager";

function App() {
    function Template() {
        if (["development", "testing", "production", "staging"].includes(process.env.NODE_ENV)) {
            window.BOLibrary = Library;
        }

        /* #region STATES */
        const ref = useRef();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const location = useLocation();
        const path = location.pathname;

        const isExceptionURL =
            path.startsWith("/pre-enrollment-invited") ||
            path.startsWith("/pre-enrollment") ||
            path.startsWith("/login");

        const {
            getUserDataToken,
            setGetUserDataToken,
            hasUnsavedChanges,
            setHasUnsavedChanges,
            isLoadingApp,
            setIsLoadingApp,

            showDialogBox,
            setShowDialogBox,
            dialogBoxLoading,
            setDialogBoxLoading,
            dialogBoxTitleHeading,
            setDialogBoxTitleHeading,
            dialogBoxTitle,
            setDialogBoxTitle,
            dialogBoxMessage,
            setDialogBoxMessage
        } = React.useContext(AppContext);
        const [showMasterLayout, setShowMasterLayout] = useState(!isExceptionURL);

        const [navigationBarMenuBtns, setNavigationBarMenuBtns] = useState([]);
        const [showTAC, setShowTAC] = useState(false);
        const [controlBarLeftBtns, setControlBarLeftBtns] = useState([]);
        const [controlBarCenterBtns, setControlBarCenterBtns] = useState([]);
        const [controlBarRigthBtns, setControlBarRightBtns] = useState([]);

        const [isMobile, setIsMobile] = useState(false);
        const [layoutType, setLayoutType] = useState("Grid");
        const hasSubsPlanAndOrgName = () => {
            const hasOrganizationName = ![null, undefined, "", "undefined", "null", "-"].includes(
                localStorage.getItem("currentOrganizationName")
            );

            return hasOrganizationName;
        };
        const [headerCenterBtns, setHeaderCenterBtns] = useState([
            { title: "New BOIMAGE", disabled: hasSubsPlanAndOrgName() ? false : true, to: "/boimages/create" },
            { title: "New Store", disabled: true, to: "/stores/create" }
        ]);

        const [filterHeaderProperties, setFilterHeaderProperties] = useState({
            visible: true,
            onFilterRefreshAction: undefined,
            onSearch: undefined,
            onRefreshAction: undefined,
            filterOptions: undefined,
            onFilterAction: undefined,
            onResetAction: undefined,
            className: undefined
        });

        /* #endregion */

        /* #region METHODS */
        const getUserDataFunction = (
            id,
            currentCompanyID = undefined,
            currentGroupID = undefined,
            redirectToHomepage = false
        ) => {
            //const formData = { token: id, currentCompanyID: currentCompanyID, currentGroupID: currentGroupID };

            const formData = { BOstagesAccountID: id };
            Library.makePostRequest("getUserData", formData, false, setIsLoadingApp).then(res => {
                if (res.data.status === 200) {
                    setShowMasterLayout(true);
                    const data = res.data.data;

                    //const status = data?.BOStagesAccount?.status;
                    //const billingPlan = data?.currentCompany?.billingPlan;
                    const userName =
                        data?.BOStagesAccount?.firstName +
                        (data?.BOStagesAccount?.lastName ? " " + data?.BOStagesAccount?.lastName : "");
                    const userEmail = data?.BOStagesAccount?.email;

                    const currentCompanyName = data?.currentCompany?.name;
                    const currentCompanyID = data?.currentCompany?._id;
                    const currentCompanyLogo = data?.currentCompany?.logoURL;
                    const currentCompanyStatus = data?.currentCompany?.status;
                    const currentOrganizationID = data?.organization?._id;
                    const currentOrganizationName = data?.organization?.name;
                    const currentOrganizationLogo = data?.organization?.mainImageUrl;
                    const activeBrands = data?.activeBrands;
                    const activeStores = data?.activeStores;
                    const activeCompanies = data?.activeCompanies;
                    const activeFFCenters = data?.activeFFCenters;
                    const activeRetailBrands = data?.activeRetailBrands;
                    const NDAaccepted = data?.NDAaccepted ?? false;
                    const profilePhoto = data?.BOStagesAccount?.profilePhotoUrl;
                    const currentProfileRole = data?.role;
                    const subscriptionPlanID = data?.organization?.subscriptionPlanID;
                    const subscriptionPlanName = data?.organization?.subscriptionPlanName;

                    let currentStatus = currentCompanyStatus;
                    if (currentCompanyStatus === "Inactive") {
                        currentStatus = data?.invite?.status?.status ?? "Inactive";
                    } else if (!currentCompanyStatus) {
                        currentStatus = "Inactive";
                    }

                    localStorage.setItem("status", currentStatus);
                    localStorage.setItem("billingPlan", subscriptionPlanName || "-");
                    localStorage.setItem("subscriptionPlanID", subscriptionPlanID);
                    localStorage.setItem("userName", userName);
                    localStorage.setItem("userEmail", userEmail);
                    localStorage.setItem("currentCompanyName", currentCompanyName);
                    localStorage.setItem("currentCompanyID", currentCompanyID);
                    localStorage.setItem("currentCompanyLogo", currentCompanyLogo);
                    localStorage.setItem("currentOrganizationID", currentOrganizationID);
                    localStorage.setItem("currentOrganizationName", currentOrganizationName);
                    localStorage.setItem("activeCompanies", activeCompanies ?? "-");
                    localStorage.setItem("activeBrands", activeBrands ?? "-");
                    localStorage.setItem("activeStores", activeStores ?? "-");
                    localStorage.setItem("activeFFCenters", activeFFCenters ?? "-");
                    localStorage.setItem("activeRetailBrands", activeRetailBrands ?? "-");
                    localStorage.setItem("currentOrganizationName", currentOrganizationName ?? "-");
                    localStorage.setItem("NDAaccepted", NDAaccepted ?? false);
                    if (profilePhoto) localStorage.setItem("currentProfilePhoto", profilePhoto);
                    localStorage.setItem("currentProfileRole", currentProfileRole ?? "--");
                    localStorage.setItem("currentOrganizationLogo", currentOrganizationLogo);

                    localStorage.setItem("labelBrands", JSON.stringify(data?.labelBrands) ?? "[]");
                    localStorage.setItem("retailBrands", JSON.stringify(data?.retailBrands) ?? "[]");
                    localStorage.setItem("invite", JSON.stringify(data?.invite));

                    //redux save userData
                    dispatch(setData({ allUserInfo: data.allUserData, userAppData: data }));

                    //redirect to home
                    if (redirectToHomepage) {
                        setShowMasterLayout(true);
                        navigate(APP_HOMEPAGE_ROUTE);
                    }
                } else {
                    localStorage.clear();
                }
            });
        };

        const checkUserLoggedIn = () => {
            const auth = localStorage.getItem("token");

            if (isExceptionURL) {
                setShowMasterLayout(false);
                localStorage.clear();
                return;
            } else if (!auth && path !== "/") {
                setShowMasterLayout(false);
                localStorage.clear();
                navigate("/");
                return;
            } else if ((path === "/" || path === APP_HOMEPAGE_ROUTE) && auth) {
                const hasSubscriptionPlan = ![null, undefined, "", "undefined", "null", "--", "-"].includes(
                    localStorage.getItem("subscriptionPlanID")
                );
                const hasOrganizationName = ![null, undefined, "", "undefined", "null", "--", "-"].includes(
                    localStorage.getItem("currentOrganizationName")
                );

                if (!hasSubscriptionPlan) navigate("/billing");
                else if (!hasOrganizationName) navigate("/account");
                else if (path !== APP_HOMEPAGE_ROUTE) navigate(APP_HOMEPAGE_ROUTE);
            }
        };

        const shouldShowMobileWarning = () => {
            const path = location.pathname;
            const exceptionURL = path.startsWith("/pre-enrollment-invited") || path.startsWith("/pre-enrollment");

            return isMobile && !exceptionURL;
        };

        const getRouteName = () => {
            const path = location.pathname;
            const routes = path.split("/");
            return routes[1];
        };
        /* #endregion */

        /* #region EFFECTS */
        const blocker = useBlocker(() => {
            return hasUnsavedChanges;
        });

        if (blocker.state === "blocked") {
            const heading = "Just checking...";
            const title = "Abandon editing?";
            const message = "Your changes will not be saved.";
            setDialogBoxTitleHeading(heading);
            setDialogBoxTitle(title);
            setDialogBoxMessage(message);
            setDialogBoxLoading(false);
            setShowDialogBox(true);
        }

        const logoutIfNoToken = () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setShowMasterLayout(false);
                navigate("/");
            }
        };

        // This useEffect is called when the location changes
        useEffect(() => {
            const path = location.pathname;
            const navigationBarMenuBtns = navigationBar(path);
            setNavigationBarMenuBtns(navigationBarMenuBtns);

            setLayoutType("Grid");

            checkUserLoggedIn();
        }, [location, localStorage.getItem("subscriptionPlanID"), localStorage.getItem("currentOrganizationName")]);

        useEffect(() => {
            const token = localStorage.getItem("token");

            if (!token) {
                setShowMasterLayout(false);
                return;
            }
            const interval = setInterval(logoutIfNoToken, 2000);
            return () => clearInterval(interval);
        }, [localStorage.getItem("token")]);

        useEffect(() => {
            if (localStorage.getItem("token")) {
                getUserDataFunction(
                    localStorage.getItem("token"),
                    localStorage.getItem("currentCompanyID"),
                    localStorage.getItem("currentGroupID")
                );
            }
        }, [dispatch]);

        useEffect(() => {
            if (!getUserDataToken) return;

            getUserDataFunction(getUserDataToken, undefined, undefined, true);
            setGetUserDataToken(null);
        }, [getUserDataToken]);

        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth <= 1024) {
                    setIsMobile(true);
                } else {
                    setIsMobile(false);
                }
            };

            handleResize();
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);

        useEffect(() => {
            if (window.location.hostname === "retailerr.bostages.com") {
                const path = window.location.pathname;
                window.location.href = `https://retailer.bostages.com${path}`;
            }
        }, []);
        /* #endregion */

        /* #region APP PROPS */
        const MainLayoutProps = {
            setHeaderCenterBtns: setHeaderCenterBtns,
            setControlBarLeftBtns: setControlBarLeftBtns,
            setControlBarCenterBtns: setControlBarCenterBtns,
            setControlBarRightBtns: setControlBarRightBtns,
            setFilterHeaderProperties: setFilterHeaderProperties,
            filterHeaderProperties: filterHeaderProperties,
            setRefreshUserData: setGetUserDataToken //TODO: LISTEN TO THIS AND REFRESH USER DATA
            //getUserDataFunction: getUserDataFunction //TODO: THIS WILL BE REPLACED BY setRefreshUserData
        };
        /* #endregion */

        return (
            <div
                className={`AppContainer route-${getRouteName()}`}
                style={{ overflow: "hidden", position: "relative" }}>
                <ToastContainer />
                {shouldShowMobileWarning() && (
                    <div className="mobile-warning">
                        <MobileNotAvailable />
                    </div>
                )}

                <FullScreenLoader className={shouldShowMobileWarning() ? "d-none" : ""} isLoading={isLoadingApp}>
                    {showTAC && <TermsAndConditions setShowTAC={setShowTAC} />}

                    <FullScreenOverlay show={!!showDialogBox}>
                        <DialogBoxComponent
                            title={dialogBoxTitle}
                            message={dialogBoxMessage}
                            onClickYes={() => {
                                setShowDialogBox(false);
                                setDialogBoxLoading(true);
                                if (hasUnsavedChanges) {
                                    setHasUnsavedChanges(false);
                                    blocker.proceed();
                                }
                            }}
                            loading={dialogBoxLoading}
                            loadingHeading={dialogBoxTitleHeading}
                            onClickNo={() => {
                                setShowDialogBox(false);
                                setDialogBoxLoading(true);
                                if (hasUnsavedChanges) {
                                    blocker.reset();
                                }
                            }}
                        />
                    </FullScreenOverlay>

                    <QueryClientProvider client={AppQueryClient}>
                        {/* process.env.REACT_APP_IS_TESTING && (
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
                ) */}
                        <div className="wrapper" ref={ref} id="myscreen">
                            <NoInternetMarker />

                            <Suspense
                                fallback={
                                    <div>
                                        <PreLoader />
                                    </div>
                                }>
                                {showMasterLayout ? (
                                    <div className="main-wrapper">
                                        <PopupManager />
                                        <div className="navigation-container">
                                            <NavigationBar menuBtns={navigationBarMenuBtns} />
                                        </div>
                                        <div className="main-content">
                                            <div className="header-container">
                                                <Header
                                                    centerBtns={headerCenterBtns}
                                                    //hideHeader={hideHeader}
                                                    getUserDataFunction={getUserDataFunction}
                                                />
                                            </div>
                                            <div className="controlbar-container">
                                                <ControlBar
                                                    leftBtns={controlBarLeftBtns}
                                                    centerBtns={controlBarCenterBtns}
                                                    rigthBtns={controlBarRigthBtns}></ControlBar>
                                            </div>
                                            <div className="ml-24px mr-24px">
                                                <FiltersHeader
                                                    layoutType={layoutType}
                                                    onLayoutTypeChange={setLayoutType}
                                                    {...filterHeaderProperties}
                                                />
                                            </div>
                                            <div className="router-container container">
                                                <Outlet context={{ MainLayoutProps, layoutType }} />
                                            </div>
                                        </div>
                                        {location?.pathname === "/boimages/create" ||
                                        location?.pathname?.startsWith("/boimages/edit") ? (
                                            <ProductsSidePanel />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    <Outlet />
                                )}

                                {showMasterLayout ? (
                                    <div className="footer-wrapper">
                                        <Footer setShowTAC={setShowTAC} />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Suspense>
                        </div>
                    </QueryClientProvider>
                </FullScreenLoader>
            </div>
        );
    }

    const router = createBrowserRouter([
        {
            element: <Template />,
            children: AllRoutes
        }
    ]);

    return <RouterProvider router={router} />;
}

export default App;
